:root {
  /* NOTE: --section-text and --action-text should be the same because some components are shown over both. */
  --action-text: white;
  --action-bg: black;
  --action-hover-bg: #222;
  --action-disabled-text: #555;
  --section-text: #eee;
  --section-bg: #333d;
  --section-bg-solid: #333;
  --section-bg-no-blur: #333d;
  --section-accent-bg: #224166; /* highlighting part of a section */
  --section-accent-text: white;
  --section-disabled-text: gray;
  --play-section-bg: #a58e6eaa;
  --play-section-text: white;
  /* --subsection-bg  Should work with --section-text */
  --subsection-bg: #5a462e88;
  --button-bg: #5a462e;
  --button-disabled-bg: #5a462e55;
  --button-disabled-text: #999;
  --button-hover-bg: #4b3c2b;
  --button-active-bg: #4b3c2b;
  --button-border: #aaa8;
  --button-text: #eee;
  --button-accent-bg: #222;
  --button-accent-text: #eee;
  --bg-gloomhaven: url('/public/images/gloomhaven-cover.webp');
  --bg-travel: url('/public/images/cropped-world-map.webp');
  --bg-party: url('/public/images/sleeping-lion.webp');
  --bg-page: url('/public/images/cropped-page-left.webp');
  --bg-color: #1b1b1b;
  --bg-text: #e5e5e5;
  --image-bg: #0008; /* Show on top of images. */
  --image-text: white; /* Show on top of images. */
  --figure-color2: #000;
  /* #39250E dark red border */
  /* #5A462E medium red border */
  /* #8FAB7B tan bg #191919 black fg */
  /* #224166 blue fg */
}
[button-theme='gold'] {
  --subsection-bg: #ffd70033;
  --button-bg: gold;
  --button-disabled-bg: #ffd70055;
  --button-disabled-text: #555;
  --button-hover-bg: #e1c20d;
  --button-active-bg: #e1c20d;
  --button-border: #444;
  --button-text: black;
  --button-accent-bg: #224166;
  --button-accent-text: white;
}
[button-theme='white'] {
  --subsection-bg: #0004;
  --button-bg: white;
  --button-disabled-bg: #777;
  --button-disabled-text: #555;
  --button-hover-bg: #ddd;
  --button-active-bg: #aaa;
  --button-border: lightgrey;
  --button-text: black;
  --button-accent-bg: #224166;
  --button-accent-text: white;
}
[button-theme='brown'] {
  --subsection-bg: #5a462e88;
  --button-bg: #5a462e;
  --button-disabled-bg: #5a462e55;
  --button-disabled-text: #999;
  --button-hover-bg: #4b3c2b;
  --button-active-bg: #4b3c2b;
  --button-border: #aaa8;
  --button-text: #eee;
  --button-accent-bg: #222;
  --button-accent-text: #eee;
}
[bg-theme='page'] {
  --bg-gloomhaven: url('/public/images/cropped-page-left.webp');
  --bg-travel: url('/public/images/cropped-page-left.webp');
  --bg-party: url('/public/images/cropped-page-left.webp');
  --bg-page: url('/public/images/cropped-page-left.webp');
}
[bg-theme='black'] {
  --play-section-bg: #a58e6e88;
  --bg-gloomhaven: none;
  --bg-travel: none;
  --bg-party: none;
  --bg-page: none;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
}
body {
  touch-action: manipulation;
}
html,
body {
  height: 100%;
  width: 100%;
  padding: 0px;
  font-family: OpenSans;
  overscroll-behavior-x: none;
}
.svelte-div {
  /* The div wrapper in src/app.html */
  position: fixed; /* prevent bounce on iOS */
  width: 100%;
  height: 100%;
  overflow: auto;
}
body {
  color: var(--bg-text);
  background-color: var(--bg-color);
}
a.button {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  padding: 0;
  margin: 0;
  border-radius: 0.5em;
  border: 1px solid var(--button-border);
  min-height: 2em;
  min-width: 4em;
  padding-inline: 0.5em;
  background-color: var(--button-bg);
  color: var(--button-text);
  font-weight: 500;
}
button {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  padding: 0;
  margin: 0;
  font-size: inherit;
}
button.default {
  border-radius: 0.5em;
  border: 1px solid var(--button-border);
  min-height: 2em;
  min-width: 4em;
  padding-inline: 0.5em;
  background-color: var(--button-bg);
  color: var(--button-text);
  font-weight: 500;
}
button.default.max {
  min-width: max-content;
}
button.default[disabled] {
  background-color: var(--button-disabled-bg);
  color: var(--button-disabled-text);
  font-weight: normal;
}
button.default:hover:not([disabled]) {
  background-color: var(--button-hover-bg);
}
button.default:active:not([disabled]) {
  background-color: var(--button-active-bg);
}
button.default.brown {
  border: 1px solid #aaa8;
  background-color: #5a462e;
  color: #eee;
}
button.default.brown[disabled] {
  background-color: #5a462e55;
  color: #999;
}
button.default.brown:hover:not([disabled]) {
  background-color: #4b3c2b;
}
button.default.brown:active:not([disabled]) {
  background-color: #4b3c2b;
}
button.nav {
  /* Larger and fully rounded radius than default */
  color: var(--button-text);
  background-color: var(--button-bg);
  border-radius: 5em;
  border: 1px solid var(--button-border);
  padding: 0.25em;
  min-width: 5em;
  height: 2.5em;
}
button.nav:hover {
  background-color: var(--button-hover-bg);
}
button.icon {
  /* Assumed to have a section bg */
  background: transparent;
  color: var(--section-text);
}
button.icon:disabled,
button.icon[disabled] {
  color: var(--section-disabled-text);
  filter: brightness(50%);
}
button.transparent {
  background: transparent;
}
button.stat {
  /* Assumed to have a section bg */
  background-color: var(--action-bg);
  color: var(--action-text);
  border: 1px solid var(--action-text);
  border-radius: 0.25em;
  padding: 0.125em;
}
button.stat:disabled,
button.stat[disabled] {
  color: var(--section-disabled-text);
}
button.menu,
button.action {
  /* Assumed to have a --action-bg */
  background: transparent;
  color: var(--action-text);
}
button.menu:disabled,
button.menu[disabled],
button.action:disabled,
button.action[disabled] {
  color: var(--action-disabled-text);
}
button.menu:hover,
button.action:hover {
  background-color: var(--action-hover-bg);
}
button.menu {
  text-align: start;
  width: 100%;
}
button.image-overlay {
  /* icon buttons over images */
  width: max-content;
  height: max-content;
  background-color: var(--image-bg);
  color: var(--image-text);
  padding: 0.25em;
  border-radius: 0.25em;
}
button.image,
button.overlay-card,
button.overlay-image {
  background-color: transparent;
  width: max-content;
  height: max-content;
  border-radius: 0.25em;
}
button.red {
  color: #d55e00;
}
button svg {
  display: block;
}
svg * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
hr {
  grid-column: 1/-1;
  width: 100%;
  height: 1px;
}
hr.dashed {
  border-style: dashed;
}
.notes-checkboxes {
  border: 1px dashed #888;
  border-radius: 0.25em;
  padding: 0.25em;
}
.subsection {
  /* A section inside a section, typically a row of buttons */
  padding: 0.5em;
  border: 1px solid #fff4;
  border-radius: 10em;
  background-color: var(--subsection-bg);
  color: var(--section-text);
  max-width: max-content;
}
section {
  padding: 0.5em;
  border: 1px solid #fff4;
  border-radius: 0.5em;
  background-color: var(--section-bg);
  color: var(--section-text);
  min-width: min(41em, 100%);
}
section a {
  color: aqua;
}
li,
p {
  max-width: 38em;
}
.mw-21 {
  max-width: 21em;
}
.height100 {
  height: 100%;
}
.width100 {
  width: 100%;
}
.width50 {
  width: 50%;
}
.list {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  align-items: start;
}
.list-center {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  align-items: center;
}
.list.center {
  align-items: center;
}
.subsection,
.row {
  display: flex;
  gap: 0.5em;
  align-items: center;
}
.inline-row {
  display: inline-flex;
  gap: 0.5em;
  align-items: center;
}
.row-reverse {
  display: flex;
  flex-direction: row-reverse;
  gap: 0.5em;
  align-items: center;
}
.wrap {
  flex-wrap: wrap;
}
.flex0 {
  flex: 0;
}
.flex1 {
  flex: 1;
}
.overflow {
  overflow: auto;
}
.gutter {
  scrollbar-gutter: stable;
}
.list.stretch,
.row.stretch {
  align-items: stretch;
}
.row.start {
  align-items: start;
}
.list.end,
.row.end {
  align-items: end;
}
.overflowy {
  overflow-y: auto;
  width: 100%;
}
.grid {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 0.5em;
}
.grid2 {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  gap: 0.5em;
}
.row1 {
  grid-row: 1;
}
.row2 {
  grid-row: 2;
}
.row3 {
  grid-row: 3;
}
.row4 {
  grid-row: 4;
}
.row5 {
  grid-row: 5;
}
.row6 {
  grid-row: 6;
}
.row7 {
  grid-row: 7;
}
.row8 {
  grid-row: 8;
}
.row9 {
  grid-row: 9;
}
.col1 {
  grid-column: 1;
}
.col2 {
  grid-column: 2;
}
.col3 {
  grid-column: 3;
}
.col4 {
  grid-column: 4;
}
.col5 {
  grid-column: 5;
}
.col6 {
  grid-column: 6;
}
.col7 {
  grid-column: 7;
}
.col8 {
  grid-column: 8;
}
.col9 {
  grid-column: 9;
}
.col10 {
  grid-column: 10;
}
.span2 {
  grid-column-end: span 2;
}
.span3 {
  grid-column-end: span 3;
}
.span4 {
  grid-column-end: span 4;
}
.span5 {
  grid-column-end: span 5;
}
.span-row {
  grid-column: 1 / -1;
}
.span-end {
  grid-column-end: -1;
}
.justify-start {
  justify-self: start;
}
.justify-center {
  justify-self: center;
}
.justify-end {
  justify-self: end;
}
.align-start {
  align-self: start;
}
.align-center {
  align-self: center;
}
.align-end {
  align-self: end;
}
.text-start {
  text-align: start;
}
.text-end {
  text-align: end;
}
.text-center {
  text-align: center;
}
.background {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: var(--bg-color);
  background-image: var(--bg-page);
  color: var(--bg-text);
}
.page {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em;
}
.no-bullets {
  list-style-type: none;
}
.font-1p5 {
  font-size: 1.5em;
}
.font-p75 {
  font-size: 0.75em;
}
.margin-p5 {
  margin: 0.5em;
}
.margin-p25 {
  margin: 0.25em;
}
.pad-1 {
  padding: 1em;
}
.pad-p5 {
  padding: 0.5em;
}
.pad-p25 {
  padding: 0.25em;
}
.gap-1 {
  gap: 1em;
}
.gap-p5 {
  gap: 0.5em;
}
.gap-p25 {
  gap: 0.25em;
}
.gap-0 {
  gap: 0;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.clip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}
.nowrap {
  white-space: nowrap;
}
.overlay-image {
  display: grid;
  align-items: center;
  justify-items: center;
  color: var(--image-text);
  font-family: OpenSans;
  text-shadow: -1px -1px black;
}
.overlay-image > * {
  grid-area: 1/1;
}
.overlay-card {
  display: grid;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  justify-items: center;
  color: var(--image-text);
  font-family: OpenSans;
}
.overlay-card > * {
  /* Setting a filter in CSS changes the order things are rendered,
     potentially hiding one element behind another. This explicitly sets a
     no-effect filter to force rendering order to be unchanged if one of the
     children adds a custom filter. */
  filter: grayscale(0%);
}
.overlay-card :first-child {
  grid-row: 1 / -1;
  grid-column: 1;
}
.overlay-card > .top-half {
  grid-row: 1;
  grid-column: 1;
  margin: 0.25em;
}
.overlay-card > .bottom-half {
  grid-row: 2;
  grid-column: 1;
  margin: 0.25em;
}
.overlay-card > .full {
  grid-row: 1 / -1;
  grid-column: 1;
  margin: 0.25em;
}
.contents {
  display: contents;
}
span.icon {
  display: inline-block;
}
.icon.tiny {
  width: 0.875em;
  height: 0.875em;
  min-width: 0.875em;
}
.icon.small {
  width: 1.25em;
  height: 1.25em;
  min-width: 1.25em;
}
.icon.normal {
  width: 1.75em;
  height: 1.75em;
  min-width: 1.75em;
}
.icon.big {
  width: 3em;
  height: 3em;
  min-width: 3em;
}
.icon.portrait {
  width: 4em;
  height: 4em;
}
svg,
svg *,
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.capitalize {
  text-transform: capitalize;
}
.book-pages.dim {
  filter: brightness(75%);
}
details[open] summary {
  margin-bottom: 0.5em;
}
.figure-summon {
  --figure-color: #fff;
}
.figure-boss,
.figure-summon_red {
  --figure-color: #d55e00;
}
.figure-summon_orange {
  --figure-color: #e69f00;
}
.figure-elite,
.figure-summon_yellow {
  --figure-color: #f0e442;
}
.elite {
  color: #f0e442;
}
.figure-summon_green {
  --figure-color: #009e73;
}
.figure-summon_blue {
  --figure-color: #56b4e9;
}
.figure-summon_purple {
  --figure-color: #0072b2;
}
.figure-summon_violet {
  --figure-color: #cc79a7;
}
.figure-other,
.figure-normal,
.figure-summon_white {
  --figure-color: #ddd;
}
.figure-objective {
  --figure-color: #ecbd4a;
}
.figure-angry {
  --figure-color: #77c1ed;
}
.figure-brute {
  --figure-color: #5e7ebd;
}
.figure-circles {
  --figure-color: #e186a3;
}
.figure-cragheart {
  --figure-color: #8b9552;
}
.figure-cthulhu {
  --figure-color: #98c6bb;
}
.figure-diviner {
  --figure-color: #addae4;
}
.figure-eclipse {
  --figure-color: #9ea0c9;
}
.figure-lightning {
  --figure-color: #c5665e;
}
.figure-mindthief {
  --figure-color: #6b7c9b;
}
.figure-music {
  --figure-color: #d78e87;
}
.figure-saw {
  --figure-color: #dfddd0;
}
.figure-scoundrel {
  --figure-color: #afd085;
}
.figure-spears {
  --figure-color: #ce8d4a;
}
.figure-spellweaver {
  --figure-color: #b081b1;
}
.figure-sun {
  --figure-color: #ebc468;
}
.figure-swords {
  --figure-color: #d39382;
}
.figure-tinkerer {
  --figure-color: #c2b698;
}
.figure-triforce {
  --figure-color: #9f9e9e;
}
.figure-two_minis {
  --figure-color: #a77766;
}
.figure-demolitionist {
  --figure-color: #da6127;
}
.figure-hatchet {
  --figure-color: #56a1cf;
}
.figure-red-guard {
  --figure-color: #e2252e;
}
.figure-red_guard {
  --figure-color: #e42939;
}
.figure-voidwarden {
  --figure-color: #dcdad9;
}
.figure-banner-spear {
  --figure-color: #ebb624;
}
.figure-banner_spear {
  --figure-color: #f8cd52;
}
.figure-blinkblade {
  --figure-color: #0dadd9;
}
.figure-boneshaper {
  --figure-color: #33b351;
}
.figure-deathwalker {
  --figure-color: #9fa5b3;
}
.figure-drifter {
  --figure-color: #a78776;
}
.figure-geminate {
  --figure-color: #c52567;
}
.figure-infuser {
  --figure-color: #6fb54e;
}
.figure-pyroclast {
  --figure-color: #eb5b38;
}
.figure-shattersong {
  --figure-color: #c0d7e9;
}
.figure-trapper {
  --figure-color: #ddb586;
}
.figure-pain-conduit {
  --figure-color: #7476a7;
}
.figure-snowdancer {
  --figure-color: #83b5be;
}
.figure-frozen-fist {
  --figure-color: #88abc6;
}
.figure-deepwraith {
  --figure-color: #987bab;
}
.figure-crashing-tide {
  --figure-color: #4c988b;
}
.figure-hive {
  --figure-color: #d7ad47;
}
.figure-metal-mosaic {
  --figure-color: #ad8f68;
}
.figure-amber-aegis {
  --figure-color: #f3bb1c;
}
.figure-artificer {
  --figure-color: #77cde1;
}
.figure-bombard {
  --figure-color: #ae9b85;
}
.figure-brightspark {
  --figure-color: #f0d43c;
}
.figure-chainguard {
  --figure-color: #d87c3f;
}
.figure-chieftain {
  --figure-color: #7acdc9;
}
.figure-fire-knight {
  --figure-color: #ec3a29;
}
.figure-hierophant {
  --figure-color: #dfe279;
}
.figure-hollowpact {
  --figure-color: #8c468e;
}
.figure-luminary {
  --figure-color: #b187be;
}
.figure-mirefoot {
  --figure-color: #f36c25;
}
.figure-ruinmaw {
  --figure-color: #ba2f2d;
}
.figure-spirit-caller {
  --figure-color: #98cb3c;
}
.figure-starslinger {
  --figure-color: #565eaa;
}
.figure-rimehearth {
  --figure-color: #a6e9ff;
}
.figure-tempest {
  --figure-color: #66c9fc;
}
.figure-shardrender {
  --figure-color: #ffd04f;
}
.figure-incarnate {
  --figure-color: #c63072;
}
.figure-thornreaper {
  --figure-color: #fbff96;
}
.figure-vanquisher {
  --figure-color: #b7796f;
}
.figure-completed {
  --figure-color: #bbb;
  --figure-color2: #333;
}
.invert .figure-completed {
  --figure-color: #333;
  --figure-color2: #bbb;
}
.standee-grid {
  display: inline-grid;
  align-items: center;
  justify-items: center;
  text-align: center;
  color: var(--figure-color);
  background-color: var(--figure-color2);
  border-radius: 50%;
  border: 0.1875em solid var(--figure-color);
}
.standee-grid > * {
  grid-area: 1/1;
}
.invert .standee-grid {
  background-color: var(--figure-color);
  color: var(--figure-color2);
  border: 0.05em solid #0006;
}
.svg-standee,
.svg-portrait,
.svg-figure,
.svg-figure svg.icon {
  color: var(--figure-color);
}
.svg-figure .number {
  color: var(--figure-color);
  fill: var(--figure-color);
  stroke: none;
}
.invert .svg-figure .number {
  fill: #000;
}
.svg-figure .circle {
  stroke: var(--figure-color);
  fill: #000;
}
.invert .svg-figure .circle {
  stroke: #0006;
  stroke-width: 0.5;
  fill: var(--figure-color);
}
@media screen and (min-width: 500px) and (min-height: 500px) {
  /* blur can make scrolling jittery. Only enable it on larger devices. */
  /* Maybe check window.navigator.deviceMemory to identify slow devices. */
  section {
    /*
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    */
    background-color: var(--section-bg-no-blur);
  }
}
@font-face {
  font-family: PirataOne;
  src: url('/public/fonts/PirataOne-Gloomhaven.eot');
  src: url('/public/fonts/PirataOne-Gloomhaven.woff') format('woff'),
    url('/public/fonts/PirataOne-Gloomhaven.ttf') format('truetype'),
    url('/public/fonts/PirataOne-Gloomhaven.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: OpenSans;
  src: url('/public/fonts/opensans/OpenSans-Regular.ttf');
}
@font-face {
  font-family: Majalla;
  src: url('/public/fonts/majallab.eot');
  src: url('/public/fonts/majallab.woff') format('woff'),
    url('/public/fonts/majallab.ttf') format('truetype'),
    url('/public/fonts/majallab.svg') format('svg');
}
@font-face {
  font-family: HighTower;
  src: url('/public/fonts/HighTower.eot');
  src: url('/public/fonts/HighTower.woff') format('woff'),
    url('/public/fonts/HighTower.ttf') format('truetype'),
    url('/public/fonts/HighTower.svg') format('svg');
}
@font-face {
  font-family: Nyala;
  src: url('/public/fonts/nyala.eot');
  src: url('/public/fonts/nyala.woff') format('woff'),
    url('/public/fonts/nyala.ttf') format('truetype'),
    url('/public/fonts/nyala.svg') format('svg');
}
@font-face {
  font-family: Markazi;
  src: url('/public/fonts/markazi.eot');
  src: url('/public/fonts/markazi.woff') format('woff'),
    url('/public/fonts/markazi.ttf') format('truetype'),
    url('/public/fonts/markazi.svg') format('svg');
}
@font-face {
  font-family: GermaniaOne;
  src: url('/public/fonts/germaniaone.eot');
  src: url('/public/fonts/germaniaone.woff') format('woff'),
    url('/public/fonts/germaniaone.ttf') format('truetype'),
    url('/public/fonts/germaniaone.svg') format('svg');
}
